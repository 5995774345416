import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-end-session',
  templateUrl: './end-session.component.html',
  styleUrls: ['./end-session.component.scss']
})
export class EndSessionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
