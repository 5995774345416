<div class="section1">
	<mat-toolbar id="header">
		<div *ngIf="isUserLogged" id="logout-content">
			<span>Hi {{ username }}, do you want to logout?</span>
			<button matTooltip="Logout" mat-icon-button id="logout-btn" (click)="logout()">
				<mat-icon>logout</mat-icon>
			</button>
		</div>
	</mat-toolbar>
	<div class="openvidu-slogan-container">
		<a href="https://mpp.samarindakota.go.id/" target="_blank">
			<img id="form-img" src="assets//images/logo_long.png" />
		</a>
		<h4 id="slogan-text">Sipelatararan Video Call</h4>
	</div>
	<div class="form-container">
		<div class="grid">
			<form
				*ngIf="isPrivateAccess && !isUserLogged"
				[formGroup]="loginForm"
				novalidate
				(ngSubmit)="login()"
				id="form-login"
				class="form login"
			>
				<div class="form-field" id="login-username">
					<label for="login-username" [ngClass]="{ error: loginError }">
						<mat-icon matTooltip="Username">person</mat-icon>
						<span class="hidden">Username</span>
					</label>
					<input
						formControlName="username"
						autocomplete="off"
						type="text"
						name="username"
						class="form-input"
						placeholder="Username"
					/>
				</div>

				<div class="form-field" id="login-password">
					<label for="login-password" [ngClass]="{ error: loginError }">
						<mat-icon matTooltip="Password">lock</mat-icon>
						<span class="hidden">Password</span>
					</label>
					<input formControlName="password" type="password" name="password" class="form-input" placeholder="Password" />
				</div>
				<div class="roomError" id="login-error" *ngIf="loginError">Authentication failed. Try again.</div>

				<div class="form-field">
					<button mat-button id="join-btn" type="submit" [disabled]="loginForm.invalid || serverConnectionError">Login</button>
				</div>
				<div class="roomError" id="serverConnectionError" *ngIf="serverConnectionError">
					<mat-icon>error</mat-icon>
					<span>Server connection failed!</span>
				</div>
			</form>

			<form
				*ngIf="isUserLogged || !isPrivateAccess"
				[formGroup]="sessionForm"
				novalidate
				(ngSubmit)="goToVideoCall()"
				id="form-session"
				class="form login"
			>
				<div class="form-field">
					<label for="session-name-input" [ngClass]="{ error: sessionForm.get('sessionName').invalid }">
						<mat-icon matTooltip="Session name">video_camera_front</mat-icon>
						<span class="hidden">Session Name</span></label
					>
					<input
						formControlName="sessionName"
						autocomplete="off"
						id="session-name-input"
						type="text"
						name="sessionName"
						class="form-input"
						placeholder="Session Name"
						(keydown)="keyDown($event)"
					/>
					<button
						matTooltip="Generate new session name"
						mat-icon-button
						id="session-name-generator-btn"
						(click)="generateSessionName($event)"
					>
						<mat-icon>cached</mat-icon>
					</button>
				</div>
				<div class="roomError" id="requiredNameError" *ngIf="sessionForm.get('sessionName').hasError('required')">
					Session name is required
				</div>
				<div class="roomError" id="shortNameError" *ngIf="sessionForm.get('sessionName').hasError('minlength')">
					Session name is too short!
				</div>

				<div class="form-field">
					<button mat-button id="join-btn" type="submit" [disabled]="sessionForm.invalid || serverConnectionError">JOIN</button>
				</div>
				<div class="roomError" id="serverConnectionError" *ngIf="serverConnectionError">
					<mat-icon>error</mat-icon>
					<span>Server connection failed!</span>
				</div>
			</form>
		</div>
	</div>

	<mat-toolbar class="footer">
		<span>
			<a
				rel="noopener noreferrer"
				target="_blank"
				href=""
			>
				Dpmptsp Samarinda
			</a>
			on
			<a
				href="https://britech.id"
				target="_blank"
			>
				Suuport By Britech
			</a>
		</span>
		<div id="call-version">
			<span>{{ version }}</span>
		</div>
	</mat-toolbar>
</div>
