import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    private titleService:Title,
  ) {
    this.titleService.setTitle("Britech Conference");
   }

  ngOnInit(): void {
  }

}
