<div style="position: fixed;z-index: 9999;width: 100%;" class="bg-white">
	<div class="row">
		<div class="col-auto d-flex">
			<button type="button" class="btn btn-transparant align-selft-center" data-toggle="modal" data-target="#exampleModal" style="margin-left: 0.5rem;"><i class="fa fa-reorder"></i></button>
		</div>
		<div class="col">
			<img src="assets/images/logo_long.png" class="img-fluid" style="max-width: 100%;height: auto;width: 200px;padding: 8px;">
		</div>
	</div>

	<!-- Modal -->
	<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header pb-0 pt-0" style="padding-right: 0 !important;">
					<h5 class="modal-title mb-0" id="exampleModalLabel"><b> <i class="fa fa-chain"></i> Pintasasan</b></h5>
					<button type="button" class="btn btn-sm btn-transparant" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true" class="red">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-lg-3 col-4 mb-2">
							<div class="card border-0 box-shadow-1">
								<div class="card-body p-2 text-center">
									<a href="https://oss.go.id" target="_blank" class="text-center">
									  <img src="assets/images/icon/oss_1.png" alt="" class="img-fluid height-40" /><br />
									</a>
									<h5 class="text-center mb-0">PERIZINAN <br>OSS</h5>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-4 mb-2">
							<div class="card border-0 box-shadow-1">
								<div class="card-body p-2 text-center">
									<a href="https://dpmptsp.samarindakota.go.id" target="_blank" class="text-center">
									  <img src="assets/images/icon/dpmtsp_2.png" alt="" class="img-fluid height-40" /><br />
									</a>
									<h5 class="text-center mb-0">DPMPTSP <br>ONLINE</h5>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-4 mb-2">
							<div class="card border-0 box-shadow-1">
								<div class="card-body p-2 text-center">
									<a href="https://simbg.pu.go.id" target="_blank" class="text-center">
									  <img src="assets/images/icon/simbg_3.png" alt="" class="img-fluid height-40" /><br />
									</a>
									<h5 class="text-center mb-0">SISTEM PUPR <br>SIMBG</h5>
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-4 mb-2">
							<div class="card border-0 box-shadow-1">
								<div class="card-body p-2 text-center">
									<a href="https://mpp.samarindakota.go.id" target="_blank" class="text-center">
									  <img src="assets/images/icon/mpp_4.png" alt="" class="img-fluid height-40" /><br />
									</a>
									<h5 class="text-center mb-0">MPP <br>SAMARINDA</h5>
								</div>
							</div>
						</div>
            <div class="col-lg-3 col-4 mb-2">
              <div class="card border-0 box-shadow-1">
                <div class="card-body p-2 text-center">
                  <a href="http://bit.ly/mppdigitalnasional" target="_blank" class="text-center">
                    <img src="assets/images/virtual.png" alt="" class="img-fluid height-40" /><br />
                  </a>
                  <h5 class="text-center mb-0">MPP <br>Digital</h5>
                </div>
              </div>
            </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ov-videoconference
	*ngIf="!loading && !serverError"
	[tokens]="tokens"
	[recordingActivityRecordingsList]="recordingList"
	[recordingActivityRecordingError]="recordingError"
	[broadcastingActivityBroadcastingError]="broadcastingError"
	[activitiesPanelRecordingActivity]="recordingEnabled"
	[toolbarRecordingButton]="false"
	[activitiesPanelBroadcastingActivity]="broadcastingEnabled"
	[toolbarBroadcastingButton]="false"
	(onToolbarLeaveButtonClicked)="onLeaveButtonClicked()"
	(onToolbarStartRecordingClicked)="onStartRecordingClicked()"
	(onActivitiesPanelStartRecordingClicked)="onStartRecordingClicked()"
	(onToolbarStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelDeleteRecordingClicked)="onDeleteRecordingClicked($event)"
	(onActivitiesPanelForceRecordingUpdate)="onForceRecordingUpdate()"
	(onActivitiesPanelStartBroadcastingClicked)="onStartBroadcastingClicked($event)"
	(onActivitiesPanelStopBroadcastingClicked)="onStopBroadcastingClicked()"
	(onToolbarStopBroadcastingClicked)="onStopBroadcastingClicked()"
	(onNodeCrashed)="onNodeCrashed()"
	[participantName]="'Nama Anda'"
	[toolbarCaptionsButton]="false"
	[toolbarBackgroundEffectsButton]="false"
>
</ov-videoconference>
<div *ngIf="!loading && serverError" class="error">
	<mat-icon>error</mat-icon>
	<span>
		{{ serverError }}
	</span>
</div>
