<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="description" content="">
    <meta name="author" content="Britech Developer Indonesia, Dpmptsp">
    <meta name="generator" content="vc.2023.0.2">
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous">

    <!-- Bootstrap core CSS -->

    <style>
      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }
    </style>
    <!-- Custom styles for this template -->
  </head>
  <body class="text-center">
    <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
    <main role="main" class="inner cover">
      <img class="img-fluid mt-50 w-50" src="assets/images/404.webp" alt="">
      <h1 class="cover-heading mt-4">Room Not Found</h1>
      <p class="lead">Sepertinya tidak ada sesi video call untuk anda saat ini.</p>
    </main>
    <footer class="mastfoot mt-auto">
      <div class="inner">
        <p><a href="https://dpmptsp.samarindakota.go.id/">DPMPTSP Kota Samarinda</a> support by <a href="https://britech.id/">Britech</a></p>
      </div>
    </footer>
</div>
</body>
</html>
