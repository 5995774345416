<div style="position: fixed;z-index: 9999;width: 100%;" class="bg-white">
	<div class="row">
		<div class="col align-selft-center text-center">
			<img src="assets/images/britech.png" class="img-fluid" style="max-width: 100%;height: auto;width: 150px;padding: 8px;">
		</div>
	</div>	
</div>
<ov-videoconference
	*ngIf="!loading && !serverError"
	[tokens]="tokens"
	[recordingActivityRecordingsList]="recordingList"
	[recordingActivityRecordingError]="recordingError"
	[broadcastingActivityBroadcastingError]="broadcastingError"
	[activitiesPanelRecordingActivity]="recordingEnabled"
	[toolbarRecordingButton]="recordingEnabled"
	[activitiesPanelBroadcastingActivity]="broadcastingEnabled"
	[toolbarBroadcastingButton]="broadcastingEnabled"
	(onToolbarLeaveButtonClicked)="onLeaveButtonClicked()"
	(onToolbarStartRecordingClicked)="onStartRecordingClicked()"
	(onActivitiesPanelStartRecordingClicked)="onStartRecordingClicked()"
	(onToolbarStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelStopRecordingClicked)="onStopRecordingClicked()"
	(onActivitiesPanelDeleteRecordingClicked)="onDeleteRecordingClicked($event)"
	(onActivitiesPanelForceRecordingUpdate)="onForceRecordingUpdate()"
	(onActivitiesPanelStartBroadcastingClicked)="onStartBroadcastingClicked($event)"
	(onActivitiesPanelStopBroadcastingClicked)="onStopBroadcastingClicked()"
	(onToolbarStopBroadcastingClicked)="onStopBroadcastingClicked()"
	(onNodeCrashed)="onNodeCrashed()"
	[participantName]="'Britech Name'"
	[toolbarCaptionsButton]="false"
	[toolbarBackgroundEffectsButton]="false"
>
</ov-videoconference>
<div *ngIf="!loading && serverError" class="error">
	<mat-icon>error</mat-icon>
	<span>
		{{ serverError }}
	</span>
</div>