import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { CallComponent } from './components/call/call.component';
import { HomeComponent } from './components/home/home.component';
import { NavigationGuardService } from './services/guards/navigation-guard.service';
import { BlankPageComponent } from './components/blank-page/blank-page.component';
import { EndSessionComponent } from './components/end-session/end-session.component';
import { CallLoketComponent } from './components/call-loket/call-loket.component';
import { PrivateRoomComponent } from './components/private-room/private-room.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

const routes: Routes = [
	{ path: '', component: BlankPageComponent },
	{ path: 'welcome', component: WelcomeComponent },
	{ path: 'room-ended', component: EndSessionComponent },
	{ path: 'home', component: HomeComponent },
	{ path: 'admin', component: AdminDashboardComponent },
	{ path: 'counter/:roomName', component: CallLoketComponent, canActivate: [NavigationGuardService] },
	{ path: 'britech-room/:roomName', component: PrivateRoomComponent, canActivate: [NavigationGuardService] },
	{ path: ':roomName', component: CallComponent, canActivate: [NavigationGuardService] }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
